import React from 'react';

import './styles/testimonial.css';

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { MdOutlineStar } from "react-icons/md";
import image from '../assets/images/background/left-quote.png';

const Testimonial = ({
    quote,
    name,
    currentImage,
    onPrev,
    onNext,
    index,
    total,
}) => {

    return (
        <>
            <div className='testimonial-container'>
                <div className='text-side'>
                    <div className='heading'>
                        The Look
                    </div>
                    <div className='heading'>
                        And Feel
                    </div>
                    <img
                        src={image}
                        alt='quote'
                        className='quote-image'

                    />

                    <div className='ratings' >
                        <MdOutlineStar className='rating' />
                        <MdOutlineStar className='rating' />
                        <MdOutlineStar className='rating' />
                        <MdOutlineStar className='rating' />
                        <MdOutlineStar className='rating' />
                    </div>

                    <div className='text'>
                        <p>{quote}</p>
                        <h4>- {name}</h4>

                        <div className='buttons' data-aos='fade-down'>
                            <div className='prev-next-icon' onClick={onPrev}>
                                <FiChevronLeft size={22} className='icon' />
                            </div>
                            <div className='counter'>
                                {index} / {total}
                            </div>
                            <div className='prev-next-icon' onClick={onNext}>
                                <FiChevronRight size={22} className='icon' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='image-side' >
                    <div className='current-image'>
                        <img src={currentImage} alt='next' />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Testimonial;