import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer';
import { register } from 'swiper/element/bundle';

import '../styles/homescreen.css';

import image1 from '../assets/images/collection/wanthla/wantla-1.jpg';
import image2 from '../assets/images/collection/wanthla/wantla-2.jpg';
import image3 from '../assets/images/collection/wanthla/wantla-3.jpg';
import image4 from '../assets/images/collection/wanthla/wantla-4.jpg';
import image5 from '../assets/images/collection/wanthla/wantla-5.jpg';

const images = [
    image1, image2, image3, image4, image5,
];


const HomeScreen = () => {
    register();

    return (
        <>
            <Nav />
            <section className='home-screen'>
                <swiper-container
                    pagination-clickable="true"
                    navigation="true"
                    navigation-next-el=".custom-next-button"
                    navigation-prev-el=".custom-prev-button"
                    pagination-dynamic-bullets="true"
                    autoplay-delay="5000"
                    autoplay-disable-on-interaction="false"
                    center-slides="true"

                    style={
                        {
                            "--swiper-navigation-color": "#CA054D",
                            "--swiper-pagination-color": "#CA054D"
                        }
                    }


                    breakpoints={
                        JSON.stringify({
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            }
                        })
                    }
                >
                    {
                        images.map((image, index) => (
                            <swiper-slide key={index} lazy="true">
                                <img src={image} alt='home-main' className='main-image' loading="lazy" />
                            </swiper-slide>
                        ))
                    }
                </swiper-container>
            </section>
            <Footer />
        </>
    )
}

export default HomeScreen