import React, { useEffect } from 'react'

import Nav from '../components/Nav'
import Footer from '../components/Footer'

import '../styles/classicscreen.css'

import image1 from '../assets/images/collection/amo-sky-blue/amo-sky-blue-i.jpg'
import image2 from '../assets/images/collection/amo-sky-blue/amo-sky-blue-ii.jpeg'
import image3 from '../assets/images/collection/amo-sky-blue/amo-sky-blue-iii.jpeg'
import image4 from '../assets/images/collection/classic/out-of-collection.jpg'
import image5 from '../assets/images/collection/classic/out-of-collection-2.jpg'
import image6 from '../assets/images/collection/classic/out-of-collection-3.jpg'
import image7 from '../assets/images/collection/classic/out-of-collection-4.jpeg'
import image8 from '../assets/images/collection/classic/out-of-collection-5.JPG'
import image9 from '../assets/images/collection/classic/out-of-collection-6.JPG'
import image10 from '../assets/images/collection/classic/out-of-collection-7.jpeg'
import image11 from '../assets/images/collection/classic/out-of-collection-8.jpg'

const images = [
    image1, image2, image3, image4, image5,
    image6, image7, image8, image9, image10, image11
];

const ClassicScreen = () => {
    useEffect(() => {
        document.title = `Jodorry High Couture | Classic Collection`;
    }, []);

    return (
        <>
            <Nav />
            <section className='classic-screen'>
                <div className='nav-color' />

                <div className='classic-content'>
                    <div className="image-container">
                        {/*  left row */}
                        <div className="row">
                            {images.filter((image, index) => index % 2 === 0).map((image, index) => (
                                <img key={index} src={image} alt="placeholder" className="image" />
                            ))}
                        </div>
                        {/* right row */}
                        <div className="row">
                            {images.filter((image, index) => index % 2 !== 0).map((image, index) => (
                                <img key={index} src={image} alt="placeholder" className="image" />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ClassicScreen