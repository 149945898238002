import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDYtJbA7YcWgJm452T_wkTdtID8UUA_4i0",
    authDomain: "jodorry-house.firebaseapp.com",
    projectId: "jodorry-house",
    storageBucket: "jodorry-house.appspot.com",
    messagingSenderId: "887499430465",
    appId: "1:887499430465:web:4cb4773c431af6170204c0",
    measurementId: "G-5M8E9VM4HZ"
};

const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);