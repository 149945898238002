import React from 'react'
import './styles/footer.css'

import { FiFacebook, FiYoutube } from "react-icons/fi";
import { IoLogoInstagram } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { PiTiktokLogo } from "react-icons/pi";


const Footer = ({ theme }) => {
    const currentYear = new Date().getFullYear();

    return (
        <footer>
            <div className={theme === "light" ? "container-light" : "container"}>
                <p>
                    &copy; {currentYear} Jodorry. All rights reserved. | Build by {" "}
                    <a href="https://syntaxtechsolutions.com/" target="_blank" rel="noopener noreferrer">
                        Syntax Tech Solutions
                    </a>
                </p>
                <div className="social-media-icons">
                    <a href="https://syntaxtechsolutions.com/" target="_blank" rel="noopener noreferrer">
                        <IoLogoInstagram />
                    </a>
                    <a href="https://www.instagram.com/your-instagram-page" target="_blank" rel="noopener noreferrer">
                        <FiFacebook />
                    </a>
                    <a href="https://www.xing.com/your-xing-page" target="_blank" rel="noopener noreferrer">
                        <FaXTwitter />
                    </a>
                    <a href="https://www.xing.com/your-xing-page" target="_blank" rel="noopener noreferrer">
                        <PiTiktokLogo />
                    </a>
                    <a href="https://www.xing.com/your-xing-page" target="_blank" rel="noopener noreferrer">
                        <FiYoutube />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer