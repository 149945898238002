import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

import Nav from '../components/Nav';

import Footer from '../components/Footer';
import { register } from 'swiper/element/bundle';

import '../styles/collectionscreen.css';

import wantla1 from '../assets/images/collection/wanthla/wantla-1.jpg';
import wantla2 from '../assets/images/collection/wanthla/wantla-2.jpg';
import wantla3 from '../assets/images/collection/wanthla/wantla-3.jpg';
import wantla4 from '../assets/images/collection/wanthla/wantla-4.jpg';
import wantla5 from '../assets/images/collection/wanthla/wantla-5.jpg';

import wanthla1 from '../assets/images/collection/wanthla/wanthla-i.jpg';
import wanthla2 from '../assets/images/collection/wanthla/wanthla-ii.jpg';
import wanthla3 from '../assets/images/collection/wanthla/wanthla-iii.jpg';
import wanthla4 from '../assets/images/collection/wanthla/wanthla-iv.jpg';
import wanthla5 from '../assets/images/collection/wanthla/wanthla-v.jpg';

import no1 from '../assets/images/collection/wanthla/No-i.jpg';
import no2 from '../assets/images/collection/wanthla/No-ii.jpg';
import no3 from '../assets/images/collection/wanthla/No-iii.jpg';
import no4 from '../assets/images/collection/wanthla/No-iv.jpg';
import no5 from '../assets/images/collection/wanthla/No-v.jpg';

const CollectionScreen = () => {
    const { collectionName } = useParams();

    useEffect(() => {
        document.title = `Jodorry High Couture | Collection | ${collectionName}`;
    }, [collectionName]);

    register();

    const collections = [
        {
            collection: "Wanthla No.1",
            images: [
                wantla1, wantla2, wantla3, wantla4, wantla5, wanthla1, wanthla2, wanthla3, wanthla4, wanthla5, no1, no2, no3, no4, no5
            ]
        }
    ]
    return (
        <>
            <Nav />
            <section className='collection-screen'>
                <div className='nav-color' />

                <div className="collection-center">
                    <div className='collection-content'>
                        <h4>
                            {collections[0].collection}
                        </h4>
                        <div className='images-container'>
                            <swiper-container
                                pagination-clickable="true"
                                navigation="true"
                                navigation-next-el=".custom-next-button"
                                navigation-prev-el=".custom-prev-button"
                                pagination-dynamic-bullets="true"
                                autoplay-delay="5000"
                                autoplay-disable-on-interaction="false"
                                center-slides="true"

                                style={
                                    {
                                        "--swiper-navigation-color": "#CA054D",
                                        "--swiper-pagination-color": "#CA054D"
                                    }
                                }


                                breakpoints={
                                    JSON.stringify({
                                        640: {
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                        }
                                    })
                                }
                            >
                                {collections[0].images.map((image, index) => {
                                    return (
                                        <swiper-slide key={index} lazy="true"><img src={image} alt={collections.collection} className='collection-image' loading="lazy" /></swiper-slide>
                                    )
                                })
                                }
                            </swiper-container>
                        </div>
                    </div>
                </div>
            </section>
            <Footer theme="light" />
        </>
    )
}

export default CollectionScreen


