import React, { useEffect } from 'react'
import Swal from 'sweetalert2';

import '../styles/contactscreen.css'

import Nav from '../components/Nav'
import Footer from '../components/Footer'
import emailjs from '@emailjs/browser'

const ContactScreen = () => {
    useEffect(() => {
        document.title = `Jodorry High Couture | Contact Us`;
    }, []);

    const onContactUs = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'jodorryhighcouture',
                'template_jodorry',
                e.target,
                'user_vqapCNsTGfo7dazQYEl5e'
            )
            .then(
                (result) => {
                    e.target.reset();
                    return Swal.fire(
                        `${result.text}`,
                        `We will get back to you within 24 hours.`,
                        'success'
                    );
                },
                (error) => {
                    return Swal.fire('Something went wrong', `${error.text}`, 'error');
                }
            );
    };
    return (
        <>
            <Nav />
            <section
                className='contact-screen'
            >
                <div className='background'>
                    <h4 className='bold'>PLACE YOUR ORDER | BOOK AN APPOINTMENT</h4>
                    <p className='contact-text'>
                        To place an order or to book an appointment, please fill in the form below and we will get back to you within 24 hours.
                    </p>
                    <div>
                        <form
                            className='form'
                            onSubmit={onContactUs}
                        >
                            <label htmlFor='name'>Name</label>
                            <div className='form-group'>
                                <input
                                    type='text'
                                    name='name'
                                    placeholder='John Doe'
                                    className='input'
                                    autoComplete={'none'}
                                    required
                                />
                            </div>
                            <label htmlFor='email'>Email</label>
                            <div className='form-group'>
                                <input
                                    type='email'
                                    name='email'
                                    placeholder='johndoe@gmail.com'
                                    className='input'
                                    autoComplete={'none'}
                                    required
                                />
                            </div>
                            <label htmlFor='phone'>Phone</label>
                            <div className='form-group'>
                                <input
                                    type='text'
                                    name='phone'
                                    placeholder='(+27) 71 234 5678'
                                    className='input'
                                    autoComplete={'none'}
                                    required
                                />
                            </div>
                            <label htmlFor='message'>Message</label>
                            <div className='form-group form-group-textarea'>
                                <textarea
                                    name='message'
                                    placeholder='Your message here...'
                                    className='textarea'
                                    autoComplete={'none'}
                                    required
                                />
                            </div>
                            <button type='submit' className='btn'>
                                Submit Now
                            </button>
                        </form>
                    </div>
                </div>
            </section>
            <Footer theme="light" />
        </>
    )
}

export default ContactScreen