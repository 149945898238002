import React, { useRef, useEffect } from 'react'
import '../styles/testimonialscreen.css'

import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Testimonial from '../components/Testimonial';

import { Testimonials } from '../context/testimonials';

const TestimonialScreen = () => {
    const scrollableRef = useRef();

    useEffect(() => {
        document.title = `Jodorry High Couture | Testimonials`;
    }, []);

    const onPressPrev = () => {
        scrollableRef.current.scrollBy({ left: -800, top: 0, behavior: 'smooth' });
    };
    const onPressNext = () => {
        scrollableRef.current.scrollBy({ left: 800, top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <Nav />
            <section className='testimonialscreen-container'>
                <div className='testimonial-container-scrollable' ref={scrollableRef}>
                    {Object.keys(Testimonials).length > 0 &&
                        Testimonials.map((testimonial) => (
                            <Testimonial
                                key={testimonial.id}
                                quote={testimonial.Quote}
                                name={testimonial.Name}
                                currentImage={testimonial.Image}
                                onPrev={() => onPressPrev()}
                                onNext={() => onPressNext()}
                                index={testimonial.id}
                                total={Object.keys(Testimonials).length}
                            />
                        ))}
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TestimonialScreen