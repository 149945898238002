import React, { useEffect } from 'react'
import '../styles/aboutscreen.css'

import Nav from '../components/Nav'
import Footer from '../components/Footer'

import logo from '../assets/images/logo.png';
import reddress from '../assets/images/collection/grad-jodorry-red/jodorry_red_iv.jpeg';

const AboutScreen = () => {
    useEffect(() => {
        document.title = `Jodorry High Couture | About Us`;
    }, []);

    return (
        <>
            <Nav />
            <section className='about-screen'>
                <div className='nav-color' />

                <div className='about-content'>
                    <div className='md-12'>
                        <h5>About Us <span>?</span></h5>
                    </div>
                    <div className='md-12'>
                        <div className='md-6'>
                            <p>
                                <b>JODORRY</b> is a Johannesburg-based luxury clothing brand established in 2021.
                                The brand focuses on reinterpreting ideologies into avant-garde designs and sculptured clothing concepts.
                                We offer dynamic gender-neutral, menswear and womenswear pret-a-porter and once-off artisanal-inspired couture pieces.
                                Our primary design and construction focus is highlighting African craftsmanship through artisanal incorporation throughout our creations;
                                our design identity is expressive, intricate cut with bold <i>Avant-Garde</i> and <i>Artisanal infusion</i>.
                                We can take a bold stand on social sustainability and environmentally sustainable business practices through that.
                            </p>
                            <br />
                            <p>
                                <b>JODORRY</b> embraces unlimited creativity and strives to transform our clients into anything they aspire to be through our clothing.
                                We target individuals with the aligned mentality to approach our fashion ethos and willingness to play their role as responsible consumers.
                                Our studio believes that to be a technician of the silhouette, and you need to have an eye for horror, an addiction to storytelling and a good hand-sewing needle for a perfect fit.
                            </p>
                        </div>
                        <div className='md-6'>
                            <div className='image-container'>
                                <img src={logo} alt='about' className='image' />
                            </div>
                        </div>
                    </div>
                    <div className='md-12'>
                        <div className='md-3'>
                            <div className='image-container'>
                                <img src={reddress} alt='about' className='image' />
                            </div>
                        </div>
                        <div className='md-9'>
                            <h5>VISION <span>&</span> MISSION</h5>
                            <ul>
                                <li>To heal psychologocal scars of human beings around the world, through our clothing and accessories.</li>
                                <li>Turning fantasy, curiosity and history into timeless pieces for every household under the sun.</li>
                            </ul>
                            <h5>INTRICATE CUT <span>& ARTISANAL</span> INFUSION</h5>
                            <p>
                                Our Sustainability model is governed by Social sustainability:
                                Fair fashion Focus and Ethical Considerations. Environmental pillars:
                                High-quality clothes made from naturally fibered fabrics with increased lifespan and more human touch through artisanal crafting and zero waste production methods.
                                We achieve this by circulating our JODORRY documentary films, which inform our audience about our sustainability principles and how customers could play a role in helping the world achieve this.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default AboutScreen