import React, { useEffect } from 'react'

import Nav from '../components/Nav'
import Footer from '../components/Footer'

import '../styles/collaboratorsscreen.css'
import { MdOutlineInsertLink } from "react-icons/md";

const Collaborators = () => {
    useEffect(() => {
        document.title = `Jodorry High Couture | Collaborators`;
    }, []);

    const collaborators = [
        {
            name: 'Syntax Tech Solutions',
            type: 'Digital Solutions',
            url: 'https://syntaxtechsolutions.com/',
        },
        {
            name: 'Zwane Brothers',
            type: 'Artisans',
            url: '/',
        },
        {
            name: 'Nelisiwe Nyongwana',
            type: 'Model',
            url: 'https://www.instagram.com/nelisiwe.nyongwana?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
        },
        {
            name: 'Project Lens Media House',
            type: 'Film & Photography',
            url: 'https://www.instagram.com/projectlens.mediahouse?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
        },
        {
            name: 'Karabo Tebele',
            type: 'Model',
            url: 'https://www.instagram.com/theekarabotebele?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
        }
    ]

    return (
        <>
            <Nav />
            <section className='collaborators-screen'>
                <div className='nav-color' />
                <div className='collaborators-content'>
                    <div className="container">
                        {
                            collaborators.map((collaborator, index) => (
                                <div className='collaborator' key={index}>
                                    <div className='info'>
                                        <p className='heading'>{collaborator.type}</p>
                                        <p>{collaborator.name}</p>
                                        <a href={collaborator.url} target="_blank" rel="noreferrer" >
                                            <MdOutlineInsertLink size={24} className='icon' />
                                            {collaborator.name}
                                        </a>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Collaborators