import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

const VidoesScreen = () => {
    return (
        <>
            <Nav />
            <section>Vidoes Screen</section>
            <Footer />
        </>
    )
}

export default VidoesScreen