import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from '../pages/HomeScreen';
import About from '../pages/AboutScreen';
import Contact from '../pages/ContactScreen';
import Classic from '../pages/ClassicScreen';
import News from '../pages/NewsScreen';
import Testimonial from '../pages/TestimonialScreen';
import Collaborators from '../pages/Collaborators';
import Videos from '../pages/VideosScreen';
import CollectionScreen from '../pages/CollectionScreen';

const AppStack = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path='/' exact element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/collection/:collection-name' element={<CollectionScreen />} />
                    <Route path='/classic' element={<Classic />} />
                    <Route path='/collaborators' element={<Collaborators />} />
                    <Route path='/videos' element={<Videos />} />
                    <Route path='/testimonials' element={<Testimonial />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/news' element={<News />} />
                    <Route path='*' element={<Home />} />
                </Routes>
            </Router>
        </>
    );
};

export default AppStack;