import React, { useState, useRef } from 'react'
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { HiOutlineX } from "react-icons/hi";
import './styles/nav.css'

import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom';

const Nav = () => {
    const [click, setClick] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(!click);
    const dropdownRef = useRef(null);
    const dropdownRef2 = useRef(null)

    const toggleMobileDropdown = () => {
        if (window.innerWidth < 1025 && dropdownRef.current) {
            if (dropdownRef.current.classList.contains('active')) {
                dropdownRef.current.classList.remove('active')
            } else {
                dropdownRef.current.classList.add('active')
            }
        }
    }

    const toggleMobileDropdown2 = () => {
        if (window.innerWidth < 1025 && dropdownRef.current) {
            if (dropdownRef2.current.classList.contains('active')) {
                dropdownRef2.current.classList.remove('active')
            } else {
                dropdownRef2.current.classList.add('active')
            }
        }
    }

    return (
        <nav className="nav-bar">
            <Link to="/" className='mobile-view' onClick={() => setClick(true)}>
                <div className="logo-container">
                    <img src={logo} alt="logo" className="logo" />
                </div>
            </Link>
            <div className="menu-icon" onClick={handleClick}>
                {click ? <HiOutlineMenuAlt3 className='icon' /> : <HiOutlineX className='icon' />}
            </div>
            <div className={click ? 'nav-menu' : 'nav-menu active'}>
                <Link to="/about" className="nav-link" onClick={closeMobileMenu}>
                    About
                </Link>
                <div className="nav-link dropdown desktop-view">
                    Collections
                    <div className="dropdown-content">
                        <Link to="/collection/wanthla" className="nav-link">Wanthla No.1</Link>
                    </div>
                </div>
                <div className="nav-link dropdown mobile" onClick={toggleMobileDropdown}>
                    Collections
                </div>
                <div ref={dropdownRef} className="mobile-dropdown-content" onClick={closeMobileMenu}>
                    <Link to="/collection/wanthla" className="nav-link" onClick={closeMobileMenu}>Wanthla No.1</Link>
                </div>

                <Link to="/classic" className="nav-link" onClick={closeMobileMenu}>
                    Classic
                </Link>
                <Link to="/" className='desktop-view' >
                    <div className="logo-container">
                        <img src={logo} alt="logo" className="logo" />
                    </div>
                </Link>
                <div className="nav-link dropdown desktop-view">
                    Discover
                    <div className="dropdown-content">
                        <Link to="/collaborators" className="nav-link">Collaborators</Link>
                        <Link to="/testimonials" className="nav-link">Testimonials</Link>
                    </div>
                </div>
                <div className="nav-link dropdown mobile" onClick={toggleMobileDropdown2}>
                    Discover
                </div>
                <div ref={dropdownRef2} className="mobile-dropdown-content" onClick={closeMobileMenu}>
                    <Link to="/collaborators" className="nav-link" onClick={closeMobileMenu}>Collaborators</Link>
                    <Link to="/testimonials" className="nav-link" onClick={closeMobileMenu}>Testimonials</Link>
                </div>
                <Link to="/contact" className="nav-link" onClick={closeMobileMenu}>
                    Contact
                </Link>
                <Link to="/news" className="nav-link" onClick={closeMobileMenu}>
                    News
                </Link>
            </div>
        </nav>
    )
}

export default Nav