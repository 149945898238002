import Beyobo from '../assets/images/testimonials/jodorry-black.png';
import Nthabi from '../assets/images/testimonials/nthabi-white.png';
import Kamo from '../assets/images/testimonials/kamo-purple.jpeg';

export const Testimonials = [
    {
        id: 1,
        ratings: 4.5,
        Quote:
            'Nthabiseng Kgasi wears a pure white strapless cocktail dress with beaded bust detailing and a side slit. This look was designed for her Miss South Africa 2022 top 30 interview. \n\n Credits: Concept and design in collaboration with Louie Vincent.',
        Name: `Nthabiseng Kgasi | Official Miss SA '22 Top 30`,
        Image: Nthabi,
    },
    {
        id: 2,
        ratings: 5,
        Quote: 'I am blessed to have been dressed by the best tailors of our time.',
        Name: 'Beyobo Jodorry | Creative Director of Jodorry',
        Image: Beyobo,
    },
    {
        id: 3,
        ratings: 4.5,
        Quote:
            'The Jodorry team met my expectations and more. I love fashion that emphasises details and exquisite tailoring everything Jodorry stands for. I had the best graduation ceremony.',
        Name: 'Kamogelo Koboyankwe | CEO of Jodorry',
        Image: Kamo,
    },
];