import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthProvider';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import AppStack from './AppStack';

const Routes = () => {
    const { setUser } = useContext(AuthContext);
    const [initializing, setInitializing] = useState(true);

    useEffect(() => {
        const subscriber = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                if (initializing) setInitializing(false);
            } else {
                setUser(null);
                if (initializing) setInitializing(false);
            }
        });
        return subscriber;
    });

    if (initializing) return null;

    return <AppStack />;
};

export default Routes;