import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";
import { db } from '../firebase';

import '../styles/newsscreen.css'

import Swal from 'sweetalert2'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

const NewsScreen = () => {
    const [email, setEmail] = useState('');

    useEffect(() => {
        document.title = `Jodorry High Couture | Newsletter`;
    }, []);

    const onSubmit = async () => {
        if (email !== '') {
            if (!/\S+@\S+\.\S+/.test(email)) {
                Swal.fire(
                    'Error',
                    `Please enter a valid email address.`,
                    'error'
                )
            }
            else {
                const Email = email.toLowerCase();
                let emailExists = false;

                const emailListRef = collection(db, 'Emails');
                const q = query(emailListRef, where('email', '==', Email));

                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    emailExists = true;
                }

                if (!emailExists) {
                    await addDoc(emailListRef, {
                        email: Email,
                        Created: Date.now(),
                    });

                    setEmail('');
                    Swal.fire(
                        'Submitted',
                        `Thank you for subscribing to our email list.`,
                        'success'
                    );
                } else {
                    setEmail('');
                    Swal.fire('Info', `You are already in our email list.`, 'info');
                }
            }
        }
        else {
            Swal.fire(
                'Error',
                `Email address cannot be empty.`,
                'error'
            )
        }
    };
    return (
        <>
            <Nav />
            <section className='news-screen'>
                <div className='news-content'>
                    <p>Stay ahead with the newest updates by signing up for our newsletter.</p>
                    <div className="newsletter-input">
                        <input type="email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} value={email} />
                        <button onClick={() => onSubmit()}>Subscribe</button>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default NewsScreen