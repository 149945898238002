import React, { useState, createContext } from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import Swal from 'sweetalert2';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useState(() => {
        loading && setLoading(false);
    }, [loading]);

    const login = async (Email, Password) => {
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, Email, Password).then(() => {
                setLoading(false);
                window.location.href = '/';
            });
        } catch (e) {
            setLoading(false);
            let message = '';

            if (e.code === 'auth/user-not-found') {
                message = 'User not found';
            } else if (e.code === 'auth/wrong-password') {
                message = 'Wrong password';
            } else if (e.code === 'auth/invalid-email') {
                message = 'Invalid email';
            } else if (e.code === 'auth/too-many-requests') {
                message = 'Too many requests';
            } else if (e.code === 'auth/user-disabled') {
                message = 'User disabled';
            } else if (e.code === 'auth/network-request-failed') {
                message = 'Network request failed';
            } else if (e.code === 'auth/weak-password') {
                message = 'Weak password';
            } else if (e.code === 'auth/email-already-in-use') {
                message = 'Email already in use';
            } else if (e.code === 'auth/operation-not-allowed') {
                message = 'Operation not allowed';
            } else {
                message = 'Something went wrong';
            }

            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${message}`,
            });
        }
    };

    const register = async (Name, Email, Password) => {
        setLoading(true);
        try {
            await createUserWithEmailAndPassword(auth, Email, Password).then(() => {
                setDoc(doc(db, 'users', auth.currentUser.uid), {
                    Name,
                    Email,
                    Kind: 'user',
                    Date: new Date(),
                }).then(() => {
                    setLoading(false);
                    window.location.href = '/';
                });
            });
        } catch (e) {
            setLoading(false);
            let message = '';
            if (e.code === 'auth/weak-password') {
                message = 'Weak password';
            } else if (e.code === 'auth/email-already-in-use') {
                message = 'Email already in use';
            } else if (e.code === 'auth/operation-not-allowed') {
                message = 'Operation not allowed';
            } else if (e.code === 'auth/invalid-email') {
                message = 'Invalid email';
            } else if (e.code === 'auth/network-request-failed') {
                message = 'Network request failed';
            } else if (e.code === 'auth/too-many-requests') {
                message = 'Too many requests';
            } else if (e.code === 'auth/user-disabled') {
                message = 'User disabled';
            } else {
                message = 'Something went wrong';
            }

            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${message}`,
            });
        }
    };

    const forgot = async (email) => {
        setLoading(true);
        try {
            await sendPasswordResetEmail(auth, email).then(() => {
                setLoading(false);
                return Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Check your email for further instructions',
                });
            });
        } catch (e) {
            setLoading(false);
            let message = '';
            if (e.code === 'auth/invalid-email') {
                message = 'Invalid email';
            } else if (e.code === 'auth/user-not-found') {
                message = 'User not found';
            } else if (e.code === 'auth/too-many-requests') {
                message = 'Too many requests';
            } else if (e.code === 'auth/network-request-failed') {
                message = 'Network request failed';
            } else if (e.code === 'auth/user-disabled') {
                message = 'User disabled';
            } else {
                message = 'Something went wrong';
            }

            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${message}`,
            });
        }
    };

    const logout = async () => {
        setLoading(true);
        try {
            await signOut(auth).then(() => {
                setLoading(false);
                window.location.href = '/';
            });
        } catch (e) {
            setLoading(false);
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${e.message}`,
            });
        }
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                login,
                register,
                forgot,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};